import { useRouter } from 'next/router'
import { ArticleHeader } from '../components/Article'
import ArticleEntries from '../components/ArticleEntries'
import Container from '../components/Container'
import useSearch from '../hooks/useSearch'

// pages/404.js
export default function Custom404() {
  const router = useRouter()

  let searchQuery

  if (router.route === '/artikler/[...slug]') {
    searchQuery = router?.query?.slug?.[1]?.replace(/-/g, ' ') || null
  }

  const { loading, error, hits } = useSearch(
    searchQuery || '',
    'relevance',
    { optionalWords: searchQuery },
    4,
  )

  const searchHits = !loading && hits?.length > 0 && !!searchQuery

  return (
    <>
      <ArticleHeader
        title="Siden finnes ikke"
        page
        preamble="Det kan være at siden har blitt flyttet eller at lenken du trykket på for å komme hit
            ikke var riktig."
      />
      <Container>
        {searchHits ? (
          <ArticleEntries
            title={`For å hjelpe deg videre har vi gjort et søk på «${searchQuery}». Kan det være noe av dette du var ute etter?`}
            articles={hits}
          />
        ) : null}
      </Container>
    </>
  )
}
