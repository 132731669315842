import React from 'react'

import styles from './Styles.module.scss'
import BEMHelper from '../../helpers/bem'
import urlHelper from '../../helpers/urls'
import Link from '../Link'
import { getImageUrl } from '../../api/sanity'
import { Blocks } from '../../types/blocks'
import ImageFields from '../../types/image'
import Tag from '../../types/tag'
import { shouldBeSmaller } from '../../helpers/utils'

const bem = BEMHelper(styles)

interface Props {
  layout: string
  title: string
  tag?: Tag
  articles: Array<{
    _id: string
    slug: { current: string; pretty: string }
    title: string
    preamble: Blocks
    mainImage: ImageFields
    genre: string
  }>
}

const SIZES = [
  [300, 300],
  [330, 220],
]

const Collection: React.FC<Props> = ({ tag, title, layout, articles }) => {
  if (!articles) {
    return null
  }

  const showImage = layout !== 'numbered'
  const List = layout === 'numbered' ? 'ol' : 'ul'

  return (
    <section {...bem('', layout)}>
      <div>
        {tag && (
          <strong {...bem('genre')}>
            <Link href={urlHelper.tema(tag.slug, tag._type)} {...bem('genre-link')}>
              {tag.name}
            </Link>
          </strong>
        )}
        <h2 {...bem('main-title', { small: shouldBeSmaller(title) })}>{title}</h2>
      </div>

      <List {...bem('list')}>
        {articles
          ?.filter(({ _id }) => _id)
          ?.map((article, index) => {
            const facsimile = article?.mainImage?.facsimile?.isFacsimile
            const images = {
              large:
                getImageUrl(article?.mainImage)
                  .width(SIZES[1][0])
                  .height(SIZES[1][1])
                  .url() || '',
              small:
                getImageUrl(article?.mainImage)
                  .width(SIZES[0][0])
                  .height(SIZES[0][1])
                  .url() || '',
            }

            return (
              <li {...bem('item')} key={`${index}-${article?._id}`}>
                <Link href={urlHelper.article(article?.slug)} {...bem('link')}>
                  <>
                    {showImage ? (
                      <div {...bem('image-wrapper', { facsimile })}>
                        <picture {...bem('image')}>
                          <source media="(min-width: 1080px)" srcSet={images.large} />
                          <img src={images.small} alt={article?.mainImage?.altText || ''} />
                        </picture>
                      </div>
                    ) : (
                      <span {...bem('number')}>{index + 1}</span>
                    )}

                    <div>
                      <strong {...bem('genre', 'small')}>{article?.genre}</strong>
                      <h3 {...bem('title')}>{article?.title}</h3>
                    </div>
                  </>
                </Link>
              </li>
            )
          })}
      </List>
    </section>
  )
}

export default Collection
