import React from 'react'
import Head from 'next/head'

import { baseUrl } from '../../constants'

interface Props {
  title?: string
  image?: string
  imageWidth?: number
  imageHeight?: number
  type?: string
  path?: string
  description?: string
}

const SEO: React.FC<Props> = ({
  title = 'Faktisk.',
  image = `${baseUrl}/images/faktisk-delebilde.png`,
  imageWidth = 1200,
  imageHeight = 630,
  type = 'website',
  path = '',
  description = 'Vi faktasjekker det offentlige ordskiftet og avdekker falske påstander og historier som sprer seg i det norske samfunnet.',
}) => {
  const url = `${baseUrl}/${path}`
  const titleEnd = title === 'Faktisk.' ? '' : '. Faktisk.'
  const titleString = `${title}${titleEnd}`

  return (
    <Head>
      <title>{titleString}</title>
      <meta property="og:title" content={title} />
      <meta name="twitter:title" content={title} />

      <meta name="description" content={description} />
      <meta property="og:description" content={description} />
      <meta name="twitter:description" content={description} />

      <meta property="og:type" content={type} />
      <meta property="og:url" content={url} />

      {image && <meta name="image" content={image} />}
      {image && <meta property="og:image" content={image} />}
      {image && <meta property="twitter:image" content={image} />}
      {image && imageWidth && <meta property="og:image:width" content={`${imageWidth}`} />}
      {image && imageHeight && <meta property="og:image:height" content={`${imageHeight}`} />}
      <meta name="twitter:card" content={image ? 'summary_large_image' : 'summary'} />
      <meta name="twitter:site" content="@faktisk_no" />
    </Head>
  )
}

export default SEO
