import React from 'react'

import styles from './Styles.module.scss'
import BEMHelper from '../../helpers/bem'

const bem = BEMHelper(styles)

interface Props {
  children: JSX.Element[] | JSX.Element | string
  title?: string
  spacelessMobile?: boolean
}

const Section: React.FC<Props> = ({ children, title, spacelessMobile }) => {
  const Node = title ? 'section' : 'div'

  return (
    <Node {...bem('', { 'spaceless-mobile': spacelessMobile })}>
      {title && <h2 {...bem('title')}>{title}</h2>}
      <div>{children}</div>
    </Node>
  )
}

export default Section
