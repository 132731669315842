import React, { useState, useEffect } from 'react'

import styles from './Styles.module.scss'
import BEMHelper from '../../helpers/bem'
import urlHelper from '../../helpers/urls'
import Link from '../Link'
import { getImageUrl } from '../../api/sanity'
import { shouldBeSmaller, canBeLarger } from '../../helpers/utils'
import breakLongWord from '../../helpers/breakLongWord'
import ImageFields from '../../types/image'
import { log } from 'console'

const bem = BEMHelper(styles)

interface Props {
  layout: string
  reference: {
    _id: string
    slug: { current: string; pretty: string }
    title: string
    mainImage: ImageFields
    genre: string
  }
}

const IMAGE_SIZES = {
  default: [720, 1000],
  full: [720, 1400],
  medium: [720, 720],
}

const ASPECTS = {
  default: [3 / 2, 3 / 2],
  full: [3 / 2, 16 / 9],
}

const Hero: React.FC<Props> = ({ layout, reference }) => {
  if (!reference) {
    return null
  }

  const showImage = layout !== 'text'
  const imageSizes = IMAGE_SIZES[layout] || IMAGE_SIZES.default
  const aspects = ASPECTS[layout] || ASPECTS.default

  const imageStyles = {
    '--aspect-small': aspects[0],
    '--aspect-large': aspects[1],
    '--background': reference.mainImage?.backgroundColor || '',
  } as React.CSSProperties

  const facsimile = reference.mainImage?.facsimile?.isFacsimile
  const tag = (facsimile && reference.mainImage?.facsimile?.tag) || null
  let images: any
  if (reference.mainImage) {
    images = {
      large: facsimile
        ? getImageUrl(reference.mainImage).width(imageSizes[1]).url() || ''
        : getImageUrl(reference.mainImage)
            .width(imageSizes[1])
            .height(Math.round(imageSizes[1] / aspects[1]))
            .url() || '',
      small: facsimile
        ? getImageUrl(reference.mainImage).width(imageSizes[0]).url() || ''
        : getImageUrl(reference.mainImage)
            .width(imageSizes[0])
            .height(Math.round(imageSizes[0] / aspects[0]))
            .url() || '',
    }
  } else {
    images = {}
  }
  return (
    <Link href={urlHelper.article(reference.slug)} {...bem('', layout)}>
      <>
        <div {...bem('content')}>
          <strong {...bem('genre')}>{reference?.genre}</strong>
          <h2
            {...bem('title', {
              small: shouldBeSmaller(reference.title),
              large: canBeLarger(reference.title),
            })}
          >
            {breakLongWord(reference.title)}
          </h2>
        </div>

        {showImage ? (
          <div {...bem('image-wrapper', { facsimile })} style={imageStyles}>
            {tag && <strong {...bem('tag')}>{tag}</strong>}
            <picture>
              <source media="(min-width: 760px)" srcSet={images.large} />
              <img src={images.small} alt={reference.mainImage?.altText || ''} {...bem('image')} />
            </picture>
          </div>
        ) : null}
      </>
    </Link>
  )
}

export default Hero
