import React from 'react'
import ErrorPage from 'next/error'
import { useRouter } from 'next/router'
import Custom404 from '../pages/404'

interface Props {
  pageData: Record<string, any>
  error?: {
    statusCode: number
    title: string
  }
}

export default function withErrorPage(Component) {
  const ComponentWithErrorPage: React.FC<Props> = (props) => {
    const router = useRouter()

    if (!router.isFallback && !props.pageData) {
      return <Custom404 />
    }

    if (props.error) {
      return <ErrorPage statusCode={props.error.statusCode} title={props.error.title} />
    }

    if (!props.pageData) {
      return <div>Laster…</div>
    }

    return <Component {...props} router={router} />
  }

  return ComponentWithErrorPage
}
