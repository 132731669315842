import React from 'react'

import styles from './Styles.module.scss'
import BEMHelper from '../../helpers/bem'
import urlHelper from '../../helpers/urls'
import Link from '../Link'
import breakLongWord from '../../helpers/breakLongWord'
import { getImageUrl } from '../../api/sanity'
import { Blocks } from '../../types/blocks'
import ImageFields from '../../types/image'

const bem = BEMHelper(styles)

interface Props {
  layout: string
  articles: Array<{
    _id: string
    slug: { current: string; pretty: string }
    title: string
    preamble: Blocks
    mainImage: ImageFields
    genre: string
  }>
}

const ASPECT = 3 / 2
const IMAGE_WIDTH = 800

const ArticlePromotion: React.FC<Props> = ({ layout, articles }) => {
  if (!articles) {
    return null
  }

  const showImage = layout !== 'text'
  const imageSizeSmall = [400, 400]
  const imageSizeLarge = [IMAGE_WIDTH, Math.round(IMAGE_WIDTH / ASPECT)]

  return (
    <div {...bem('', layout)}>
      {articles
        ?.filter(({ _id }) => _id)
        ?.map((article) => {
          const facsimile = article.mainImage?.facsimile?.isFacsimile
          const tag = (facsimile && article.mainImage?.facsimile?.tag) || null

          const images = {
            large: facsimile
              ? getImageUrl(article?.mainImage).width(imageSizeLarge[0]).url() || ''
              : getImageUrl(article?.mainImage)
                  .width(imageSizeLarge[0])
                  .height(imageSizeLarge[1])
                  .url() ||
                '' ||
                '',
            small: facsimile
              ? getImageUrl(article?.mainImage).width(imageSizeSmall[0]).url() || ''
              : getImageUrl(article?.mainImage)
                  .width(imageSizeSmall[0])
                  .height(imageSizeSmall[1])
                  .url() || '',
          }

          return (
            <Link key={article?._id} href={urlHelper.article(article?.slug)} {...bem('item')}>
              {showImage ? (
                <div {...bem('image-wrapper', { facsimile })}>
                  {tag && <strong {...bem('tag')}>{tag}</strong>}
                  <picture {...bem('image')}>
                    <source media="(min-width: 760px)" srcSet={images.large} />
                    <img src={images.small} alt={article?.mainImage?.altText || ''} />
                  </picture>
                </div>
              ) : null}

              <div>
                <strong {...bem('genre')}>{article?.genre}</strong>
                <h2 {...bem('title')}>{breakLongWord(article?.title)}</h2>
              </div>
            </Link>
          )
        })}
    </div>
  )
}

export default ArticlePromotion
