import React from 'react'
import dynamic from 'next/dynamic'

const PreviewMode = dynamic(import('./PreviewMode'))

export default function SanityPreview<T>({
  preview,
  initialData,
  children,
  ...props
}: {
  preview: boolean
  initialData: T
  children: (data: T) => React.ReactElement
  query: string
  params?: Record<string, unknown>
}) {
  if (!preview) {
    return children(initialData)
  }

  return (
    <PreviewMode {...props} initialData={initialData}>
      {children}
    </PreviewMode>
  )
}
