import React from 'react'
import { uniqBy } from 'lodash'

import styles from './Styles.module.scss'
import BEMHelper from '../../helpers/bem'
import Tags, { TagType } from '../Tags'

const bem = BEMHelper(styles)

interface Props {
  latest: TagType[]
}

const getTypes =
  (types: string[]) =>
  ({ _type }) =>
    types.indexOf(_type) !== -1
const limit = (_: unknown, index: number) => index < 5

const TagCloud: React.FC<Props> = ({ latest }) => {
  const uniqeTags = uniqBy(latest, '_id')
  const organization = uniqeTags.filter(getTypes(['organization'])).filter(limit)
  const person = uniqeTags.filter(getTypes(['person'])).filter(limit)
  const topic = uniqeTags.filter(getTypes(['concept', 'event'])).filter(limit)

  const types = [
    {
      _id: 'faktasjekk',
      _type: undefined,
      name: 'Faktasjekk',
      href: '/sjangere/faktasjekk',
    },
    {
      _id: 'artikkel',
      _type: undefined,
      name: 'Artikkel',
      href: '/sjangere/artikkel',
    },
    {
      _id: 'kommentar',
      _type: undefined,
      name: 'Kommentar',
      href: '/sjangere/kommentar',
    },
  ]

  return (
    <section {...bem('')}>
      <Tags title="Organisasjon" items={organization} list />
      <Tags title="Person" items={person} list />
      <Tags title="Tema" items={topic} list />
      <Tags title="Sjanger" items={types} list />
    </section>
  )
}

export default TagCloud
